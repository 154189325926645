import { saveAs } from 'file-saver';
import {
  AlignmentType,
  Document,
  //ImageRun,
  Packer,
  Paragraph,
  TabStopType,
  TextRun,
} from 'docx';

// import { convertUrlToBuffer } from '../../../helpers';
// import imagePath from '../../../assets/images/cut-logo.png';

type InputsType = { [key: string]: { placeholder: string; value: string | Date } };
export const generateDocumentDonationCertificate = async (params: InputsType) => {
  const font = 'Arial';
  const breakSpace = 1;

  const {
    name,
    NIT,
    date,
    num_legal_personality,
    name_to,
    ID_to,
    quantity,
    quantity_string,
    // year,
    concept,
    // represente_id,
    // fiscal_id,
    // fiscal_pro_card,
    // cont_pro_card,
    // cont_id,
  } = params;

  const year = new Date().getFullYear() - 1;
  const underlineText = (text: string | Date) => {
    // return text
    //   .split('')
    //   .map((char) => char + '\u0332')
    //   .join('');
    return text;
  };

  const createParagraph = (texts: string[]) => {
    return new Paragraph({
      children: texts.map(
        (text) =>
          new TextRun({
            font: font,
            text,
          })
      ),

      spacing: {
        after: 200,
      },
      tabStops: [
        {
          position: 4500,
          type: TabStopType.RIGHT,
        },
        {
          position: 2250,
          type: TabStopType.CENTER,
        },
      ],
    });
  };
  // const imageBuffer = (await convertUrlToBuffer(imagePath)) as ArrayBuffer;

  // const image = new ImageRun({
  //   data: imageBuffer,
  //   transformation: {
  //     height: 100,
  //     width: 100,
  //   },
  // });

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 34,
                text: `AÑO GRAVABLE ${underlineText(year.toString())}`,
              }),
            ],
            spacing: {
              after: 400,
              before: 400,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 34,
                text: `Iglesia`,
              }),
            ],
            spacing: {
              after: 300,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 34,
                text: `${underlineText(name.value)}`,
              }),
            ],
            spacing: {
              after: 300,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 24,
                text: `NIT. ${underlineText(NIT.value)}`,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                bold: true,
                font: font,
                size: 34,
                text: `CERTIFICADO DE DONACIONES RECIBIDAS`,
              }),
            ],
            spacing: {
              after: 600,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `La Iglesia ${underlineText(
                  name.value
                )}, entidad sin ánimo de lucro, con Personería Jurídica especial No. ${underlineText(
                  num_legal_personality.value
                )} otorgada y vigilada por el MINISTERIO DEL INTERIOR, inscrita en el Registro Único Tributario con el Nit. ${underlineText(
                  NIT.value
                )} de la Dirección de Impuestos y Aduanas Nacionales DIAN, certifica que:`,
              }),
            ],
            spacing: {
              after: 200,
            },
          }),
          new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `Recibió de ${underlineText(
                  name_to.value
                )} identificado con CC. ${underlineText(
                  ID_to.value
                )} en calidad de donación por el valor de $${underlineText(
                  quantity.value
                )} (${underlineText(quantity_string.value)} PESOS MCTE) `,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            children: [new TextRun(`Destinación: por ${concept.value}`)],
            spacing: { after: 400 },
          }),
          new Paragraph({
            alignment: AlignmentType.START,
            children: [
              new TextRun({
                break: breakSpace,
                font: font,
                text: `▪️ Somos declarantes de Ingresos y Patrimonio y cumplimos con el deber formal`,
              }),
              new TextRun({
                break: breakSpace,
                font: font,
                text: `▪️	Somos vigilados por el Ministerio del Interior`,
              }),
              new TextRun({
                break: breakSpace,
                font: font,
                text: `▪️	Manejamos los recursos en establecimientos financieros autorizados`,
              }),
              new TextRun({
                break: breakSpace,
                font: font,
                text: `▪️	La donación se realizó a través de un intermediario financiero`,
              }),
            ],
            spacing: {
              after: 400,
            },
          }),
          new Paragraph({
            children: [new TextRun(`Dado en Medellín, a los ${underlineText(date.value)}`)],
            spacing: { after: 400 },
          }),
          new Paragraph({
            children: [new TextRun(`Atentamente`)],
            spacing: { after: 400 },
          }),
          createParagraph([
            '________________',
            '      ',
            '________________',
            '        ',
            '________________',
          ]),
          createParagraph([
            'C.C. ',
            '____________',
            '       C.C. ',
            '____________',
            '       C.C. ',
            '____________',
          ]),
          createParagraph([
            'Representante Legal',
            '       ',
            'T.P. ',
            '____________',
            '       ',
            'T.P. ',
            '____________',
          ]),
          createParagraph([`\t`, `Revisor Fiscal\t`, `   Contador`]),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((buffer) => {
    saveAs(buffer, 'Certificado_de_donacion.docx');
  });
};
