import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useQuill } from 'react-quilljs';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'quill/dist/quill.snow.css';

type WysiwygComponentPropsType<T> = {
  placeholder: string;
  setState: Dispatch<SetStateAction<T>>;
  prevContent?: string | null;
  error: boolean;
};

const format = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
  // 'image'
  // 'video'
];

const optionswyswyg =
  window.screen.width > 800
    ? [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }]
    : [{}];
export const WysiwygComponent = <T,>(props: WysiwygComponentPropsType<T>) => {
  const { placeholder, setState, prevContent, error } = props;
  const { t } = useTranslation();
  const { quill, quillRef } = useQuill({
    formats: format,
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        optionswyswyg,
        [{ font: [] }, { size: [] }],
        [{ color: [] }, { background: [] }],
      ],
    },
    placeholder: placeholder,
  });

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setState((prev) => {
          return { ...prev, text: quill.root.innerHTML };
        });
      });
    }
  }, [quill, setState]);

  const initialValue = useMemo(() => {
    if (quill && prevContent === quill?.root?.innerHTML) {
      return false;
    } else {
      return prevContent;
    }
  }, [prevContent, quill]);

  useEffect(() => {
    if (quill && initialValue) {
      quill.clipboard.dangerouslyPasteHTML(initialValue);
    }
  }, [initialValue, quill]);

  return (
    <>
      <Box
        sx={{
          background: '#d4d4d48d',
          border: error ? '1px solid red' : 'none',
          display: 'flex',
          flexDirection: 'column',
          height: { sm: 150, xs: 300 },
          justifyContent: 'center',
          margin: 'auto',
          width: { sm: '70%', xs: '90%' },
        }}
      >
        <div ref={quillRef} />
      </Box>
      {error && (
        <FormHelperText
          sx={{
            color: 'red',
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
            p: { fontFamily: 'Montserrat', fontWeight: 500 },
            width: { sm: '70%', xs: '90%' },
          }}
        >
          {t('app.forms.required')}
        </FormHelperText>
      )}
    </>
  );
};
