import { useState, MouseEvent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { MenuList, Box, Drawer } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import DescriptionIcon from '@mui/icons-material/Description'; //
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import logo from '../../assets/images/nobg-logo.png';
import logoTitle from '../../assets/images/nobg-fi-logo.png';
import bgImag from '../../assets/images/fondo-login.png';
import { chPerm } from '../../helpers';
import { MenuItemComp, ListItemTextComp, ListItemIconComp, Containerbg } from './styles-sidebar';
import { Navbar } from '../navbar';
import { AuthUserType, ReminderType } from '../../types';
type SidebarPropsType = {
  user: AuthUserType;
  reminders: ReminderType[];
  window: Window;
};

const drawerWidth = 240;
const navbarHeight = 50;
export const Sidebar = (props: SidebarPropsType) => {
  const { user, window, reminders } = props;

  const [itemSelected, setItemSelected] = useState('Recordatorios');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const remindersPending = chPerm([], user)
    ? reminders.filter(
        (reminder) => reminder.status === 'pending' || reminder.status === 'in process'
      ).length
    : 0;

  useEffect(() => {
    const paths = [
      'recordatorios',
      'agendamiento',
      'requerimientos',
      'chat',
      'documentos',
      'instructivos',
    ];
    const currentPath = location.pathname.replace('/', '');
    if (paths.includes(currentPath.toLowerCase())) {
      setItemSelected(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    } else {
      setItemSelected('');
    }
  }, [location]);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectReport = (option: string) => {
    handleClose();
    if (option === 'certificates') {
      goTo(`certificados`);
      return;
    }
    goTo(`Documentos?report=${option}`);
  };

  const selectItem = (path: string) => {
    setItemSelected(path);
  };

  const goTo = (select: string) => {
    selectItem(select);
    navigate(`/${select.toLowerCase()}`);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const documentsOptions = [
    { label: t('app.layout.sidebar.document-type.laboral-certify'), value: 'work' },
    {
      label: t('app.layout.sidebar.document-type.income-and-withholdings-certify'),
      value: 'certificates',
    },
    { label: t('app.layout.sidebar.document-type.income-certify'), value: 'incomes' },
    { label: t('app.layout.sidebar.document-type.donations-certify'), value: 'donation' },
    // { label: t('app.layout.sidebar.document-type.monthly-tax-estimate'), value: '' },
  ];
  const drawer = (
    <div style={{ height: '100%' }}>
      <MenuList
        sx={{
          alignItems: 'center',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          height: '100%',
          overflowX: 'hidden',
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: { lg: '50px', xs: '20px' },
            position: { lg: 'fixed', xs: 'flex' },
            top: { lg: 30, xs: 0 },
            width: '100%',
            zIndex: 2,
          }}
        >
          <img alt="logo" src={logo} style={{ height: 48, opacity: '20%', width: '40px' }} />
          <img alt="logotitle" src={logoTitle} style={{ height: 48, width: '120px' }} />
        </Box>
        <Box
          sx={{
            display: { lg: 'block', xs: 'none' },
            height: 48,
            marginBottom: { lg: '50px', xs: '20px' },
          }}
        ></Box>
        {
          //chPerm([], user)
          true && (
            <MenuItemComp
              onClick={() => goTo('Recordatorios')}
              selected={itemSelected === 'Recordatorios'}
            >
              <ListItemIconComp className={itemSelected === 'Recordatorios' ? 'icon' : ''}>
                <NotificationsActiveIcon fontSize="medium" sx={{ color: grey[100] }} />
              </ListItemIconComp>
              <ListItemTextComp>
                {t('app.layout.sidebar.reminders')}
                {remindersPending > 0 && (
                  <div className="container-reminder">{remindersPending}</div>
                )}
              </ListItemTextComp>
            </MenuItemComp>
          )
        }
        <MenuItemComp
          onClick={() => goTo('Agendamiento')}
          selected={itemSelected === 'Agendamiento'}
        >
          <ListItemIconComp className={itemSelected === 'Agendamiento' ? 'icon' : ''}>
            <CalendarMonthOutlinedIcon fontSize="medium" sx={{ color: grey[200] }} />
          </ListItemIconComp>
          <ListItemTextComp>{t('app.layout.sidebar.bookings')}</ListItemTextComp>
        </MenuItemComp>
        <MenuItemComp onClick={() => goTo('Chat')} selected={itemSelected === 'Chat'}>
          <ListItemIconComp className={itemSelected === 'Chat' ? 'icon' : ''}>
            <TextsmsIcon fontSize="medium" sx={{ color: grey[200] }} />
          </ListItemIconComp>
          <ListItemTextComp>{t('app.layout.sidebar.chat')}</ListItemTextComp>
        </MenuItemComp>
        <MenuItemComp
          onClick={() => goTo('Requerimientos')}
          selected={itemSelected === 'Requerimientos'}
        >
          <ListItemIconComp className={itemSelected === 'Requerimientos' ? 'icon' : ''}>
            <PersonAddAltIcon fontSize="medium" sx={{ color: grey[200] }} />
          </ListItemIconComp>
          <ListItemTextComp>{t('app.layout.sidebar.requirements')}</ListItemTextComp>
        </MenuItemComp>
        <MenuItemComp
          onClick={(e) => {
            handleMenu(e);
          }}
          selected={itemSelected === 'Documentos'}
          aria-controls="menu-documents"
          aria-haspopup="true"
        >
          <ListItemIconComp className={itemSelected === 'Documentos' ? 'icon' : ''}>
            <DescriptionIcon fontSize="medium" sx={{ color: grey[200] }} />
          </ListItemIconComp>
          <ListItemTextComp>{t('app.layout.sidebar.documents')}</ListItemTextComp>
        </MenuItemComp>
        <Menu
          id="menu-documents"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {documentsOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSelectReport(option.value)}
              sx={{ fontFamily: 'Montserrat', fontWeight: 500 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <MenuItemComp
          onClick={() => goTo('Instructivos')}
          selected={itemSelected === 'Instructivos'}
        >
          <ListItemIconComp className={itemSelected === 'Instructivos' ? 'icon' : ''}>
            <ImportContactsOutlinedIcon fontSize="medium" sx={{ color: grey[200] }} />
          </ListItemIconComp>
          <ListItemTextComp>{t('app.layout.sidebar.instructives')}</ListItemTextComp>
        </MenuItemComp>
        <Containerbg>
          <div>
            <img src={bgImag} alt="img bg" />
          </div>
        </Containerbg>
      </MenuList>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 }, width: { lg: drawerWidth } }}
      aria-label="mailbox folders"
    >
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ color: '#000', display: { lg: 'none' }, ml: 1 }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: 'white',
            boxSizing: 'border-box',
            width: drawerWidth,
          },
          display: { lg: 'none', xs: 'block' },
        }}
      >
        <Navbar isMobile={true} />
        {drawer}
      </Drawer>
      <Drawer
        className="mirar-permanet"
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: 'white',
            boxSizing: 'border-box',
            height: `calc(100% - ${navbarHeight}px)`,
            top: navbarHeight + 4,
            width: drawerWidth,
          },
          display: { lg: 'block', xs: 'none' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
