import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { TextField, FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import BalanceIcon from '@mui/icons-material/Balance';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { login, sendVerifyCode } from '../../services';
import { ToastAlert } from '../../components';
import { ContainerIcons, ContainerLogin, ItemsInfoContainer } from './styles-login';
import logo from '../../assets/images/nobg-logo.png';
import logoTitle from '../../assets/images/nobg-fi-logo.png';
import bgImag from '../../assets/images/fondo-login.png';
import colFlag from '../../assets/images/colombiaFlag.png';
import usFlag from '../../assets/images/usaFlag.png';
import { AuthUserType } from '../../types';

type ErrorInputType = {
  email: boolean;
  password: boolean;
};
export const LogIn = () => {
  const [dataUser, setDataUser] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorInput, setErrorInput] = useState<ErrorInputType>({
    email: false,
    password: false,
  });
  // const [counterLastCode, setCounterLastCode] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChangeTextFields = (value: string, name: string) => {
    setDataUser((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const sendInfo = async () => {
    await login(dataUser as AuthUserType)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        ToastAlert.fire({
          icon: 'error',
          title: t(`app.auth.${error?.response?.data?.message}`),
        });
      });
  };
  const handleSend = async () => {
    setLoading(true);
    const VARS_REQUIRED: string[] = ['password', 'email'];
    let hasError = false;
    let errors = errorInput;

    for await (const [key, value] of Object.entries(dataUser)) {
      if (VARS_REQUIRED.includes(key) && value?.length === 0 && key !== 'general') {
        errors[key as keyof ErrorInputType] = true;
        hasError = true;
      } else {
        errors[key as keyof ErrorInputType] = false;
      }
    }
    setErrorInput({ ...errors });

    if (!hasError) {
      sendInfo();
    } else {
      setLoading(false);
    }
  };

  const handleSendCode = async () => {
    try {
      setLoading(true);
      await sendVerifyCode({ email: dataUser.email });
      //delete navigate
      navigate('/restaurar-contraseña/z');
    } catch (error) {
      setLoading(false);
      ToastAlert.fire({
        icon: 'error',
        title: t(`app.auth.error-very-code`),
      });
      setErrorInput({ email: true, password: false });
    }
  };

  const commonStylesInput = {
    '.MuiInputBase-root': {
      bgcolor: '#fceaec',
      borderRadius: '20px',
    },
    div: {
      '.MuiInputBase-input': {
        fontFamily: 'Montserrat',
        padding: '12.5px 10px',
      },
    },
    'div::after': {
      borderBottom: '2px solid #43494f',
    },
    label: {
      '&.Mui-focused': {
        color: '#777777',
      },
      color: '#777777',
    },
  };

  const style = {
    bgcolor: '#fff',
    border: '1px solid #9c9a9a',
    borderRadius: '20px',
    boxShadow: 24,
    left: '50%',
    overFlowY: 'scroll',
    p: { md: 4, xs: 0 },
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <ContainerLogin>
      <ContainerIcons>
        <div className="container-flags">
          <HelpIcon
            sx={{ color: '#616161c3', cursor: 'pointer', height: 40, width: 60 }}
            onClick={() => navigate('/support')}
          />
          <img alt="USA" src={usFlag} onClick={() => changeLanguage('en')} />
          <img alt="Colombia" src={colFlag} onClick={() => changeLanguage('es')} />
        </div>
      </ContainerIcons>
      <div className="container-form">
        <img src={logo} alt="logo" style={{ height: 80, opacity: '20%', width: 80 }} />
        <img src={logoTitle} alt="logo-title" style={{ height: 70, marginTop: 10, width: 240 }} />
        <h2>{t('app.auth.welcome')}</h2>
        <div className="bg-cont">
          <p className="label-input">{t('app.auth.email')}</p>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={dataUser.email}
            error={errorInput.email}
            disabled={loading}
            onChange={(e) => handleChangeTextFields(e.target.value, 'email')}
            sx={{ ...commonStylesInput, marginBottom: '20px', width: '100%' }}
          />
          <p className="label-input">{t('app.auth.password')}</p>
          <FormControl
            sx={{
              ...commonStylesInput,
              m: 0,
              width: '100%',
            }}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={dataUser.password}
              onChange={(e) => handleChangeTextFields(e.target.value, 'password')}
              error={errorInput.password}
              disabled={loading}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '5px' }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ height: 35, width: 35 }} />
                    ) : (
                      <Visibility sx={{ height: 35, width: 35 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className="buttons-container">
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSend}
              sx={{
                '&:hover': { bgcolor: '#c9233b' },
                bgcolor: '#E94F50',
                border: '1px solid #fff',
                borderRadius: '14px',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: 500,
                marginTop: '0px',
                maxWidth: 150,
                width: 150,
              }}
            >
              {t('app.auth.login')}
            </LoadingButton>
          </div>
        </div>
        <p className="user-interaction">
          {t('app.auth.forgot-password')}{' '}
          <span onClick={() => setShowModal((prev) => !prev)}>{t('app.auth.get-password')}</span>
        </p>
        <ItemsInfoContainer>
          <div className="item">
            <AccountCircleRoundedIcon sx={{ height: 50, width: 70 }} />
            <p>{t('app.auth.administratives')}</p>
          </div>
          <div className="item">
            <PaidRoundedIcon sx={{ height: 50, width: 70 }} />
            <p>{t('app.auth.financial')}</p>
          </div>
          <div className="item">
            <div className="cont">
              <BalanceIcon sx={{ fill: '#fff', height: 35, width: 45 }} />
            </div>
            <p>{t('app.auth.tributaries')}</p>
          </div>
          <div className="item">
            <div className="cont">
              <CalculateRoundedIcon sx={{ fill: '#fff', height: 35, width: 45 }} />
            </div>
            <p>{t('app.auth.accountants')}</p>
          </div>
        </ItemsInfoContainer>
      </div>
      <div style={{ position: 'relative', width: '100%', zIndex: 1 }}>
        <div className="bg-image">
          <img src={bgImag} alt="bg img" />
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal((prev) => !prev)}
        aria-labelledby="modal-send-code"
        aria-describedby="this is a modal used to send the code to restore password."
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
      >
        <Box
          sx={{
            ...style,
            top: '50%',
            transform: `translate(-50%, -40%)`,
            width: { md: '40%', xs: '80%' },
          }}
        >
          <div
            style={{
              alignItems: 'center',
              borderRadius: '14px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h2 style={{ fontWeight: 500 }}>{t('app.auth.title-recover')}</h2>
            <p className="label-input" style={{ marginBottom: 5, width: '80%' }}>
              {t('app.auth.email')}
            </p>
            <TextField
              id="email-to-send"
              variant="outlined"
              value={dataUser.email}
              error={errorInput.email}
              disabled={loading}
              onChange={(e) => handleChangeTextFields(e.target.value, 'email')}
              sx={{ ...commonStylesInput, marginBottom: '20px', width: '80%' }}
            />
            <div className="buttons-container">
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleSendCode}
                sx={{
                  '&:hover': { bgcolor: '#c9233b' },
                  bgcolor: '#E94F50',
                  border: '1px solid #fff',
                  borderRadius: '14px',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 500,
                  marginTop: '0px',
                  maxWidth: 180,
                  width: 180,
                }}
              >
                {t('app.auth.send-code')}
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
    </ContainerLogin>
  );
};
