import { useRef, useMemo, MutableRefObject, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Styles-agGrid.css';
import { Container } from './styles-table';

type AgGridTableProps<T> = {
  data: unknown[];
  columnParameters: ColDef[];
  handleClick: (selectedRows: T[]) => void;
  tableRef?: MutableRefObject<AgGridReact | null>;
};

export const AgGridTable = <T,>(props: AgGridTableProps<T>) => {
  const { data, columnParameters, handleClick, tableRef } = props;
  const gridRef = useRef<AgGridReact | null>(null);

  const gridApi = tableRef || gridRef;

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      headerClass: () => 'header-one',
      resizable: true,
      sortable: true,
    }),
    []
  );

  const TableHandleSelects = () => {
    if (gridApi.current) {
      let rowsSelected = gridApi.current.api.getSelectedRows();
      handleClick(rowsSelected);
    }
  };

  const onFirstDataRendered = useCallback(() => {
    if (gridRef?.current) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  return (
    <Container>
      <div className="ag-theme-alpine" style={{ maxHeight: '65vh', width: '100%' }}>
        <AgGridReact
          rowData={data}
          ref={gridApi}
          defaultColDef={defaultColDef}
          columnDefs={columnParameters}
          animateRows={true}
          onRowSelected={TableHandleSelects}
          paginationPageSize={50}
          pagination
          rowSelection={'single'}
          //rowMultiSelectWithClick={true}
          onFirstDataRendered={onFirstDataRendered}
          domLayout={'autoHeight'}
        />
      </div>
    </Container>
  );
};
